import { configureStore } from "@reduxjs/toolkit";

import { sessionReducer } from "redux-react-session";
import loginSlice from "../features/mainLogin/login/loginSlice";
import InputMediaSlice from "../features/SplashPage/InputMedia/InputMediaSlice";
import SplashPageSlice from "../features/SplashPage/SplashPageSlice";
import enrichmentReducer from "./reducers/enrichment-reducer";
import transcriptSlice from "../features/MediaResults/Transcript/transcriptSlice";
import settingsSlice from "../features/settings/SettingsSlice";
import MediaResultsSlice from "../features/MediaResults/MediaResultsSlice";
import MediaTabsSlice from "../features/MediaResults/MediaTabs/MediaTabsSlice";
import ResourceContentSlice from "../features/ResourceContent/ResourceContentSlice";

const env = process.env.REACT_APP_ENV;

export default configureStore({
  devTools: env==="dev" ? true : false,
  reducer: {
    enrichments: enrichmentReducer,
    session: sessionReducer,
    inputMedia: InputMediaSlice,
    transcript: transcriptSlice,
    media: SplashPageSlice,
    settings: settingsSlice,
    mediaResultsInfo: MediaResultsSlice,
    mediaTabsInfo: MediaTabsSlice,
    authInfo: loginSlice,
    share_resource: ResourceContentSlice,
  },
});
