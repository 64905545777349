import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Connection from "../../utils/Connection";
import {
  asyncGetMediaList,
  checkboxChange,
  selectDeselectAll,
} from "./SplashPageSlice";

import folderSmall from "../../assets/folderSmall.png";
import videoSmall from "../../assets/videoSmall.png";
import audioSmall from "../../assets/audioSmall.png";

export const deleteElement = async (mediaList) => {
  const conn = new Connection();
  // media.file_id, media.file_type_id
  const data = Array.isArray(mediaList)
    ? mediaList.map((media) => ({
        id: media.file_id,
        type_id: media.file_type_id,
      }))
    : [{ id: mediaList.file_id, type_id: mediaList.file_type_id, mediaList }];
  // { id, type_id };
  await conn.create("/media/delete/", data);

  return conn.getResponse();
};

export const renameElement = async (id, type_id, new_name) => {
  const conn = new Connection();
  const data = { id, type_id, new_name };
  await conn.create("/media/file/rename/", data);
  return conn.getResponse();
};

export const createFolder = async (folder_name, folder_id) => {
  const conn = new Connection();
  const data = { folder_id, folder_name };
  await conn.create("/media/folder/create/", data);
  return conn.getResponse();
};

export const editThumbnail = async (thumbnail, file_id) => {
  const conn = new Connection();
  const data = { thumbnail, file_id };
  await conn.create("/media/file/change/thumbnail/", data);
  return conn.getResponse();
};

export const useChangeFolder = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleChangeFolder = (folderFilename) => {
    const newPathname = history.location.pathname + (folderFilename + "/");
    const parent_id = history.location.state?.parent_id;
    dispatch(asyncGetMediaList(newPathname, parent_id));
    history.push(folderFilename + "/");
  };
  return handleChangeFolder;
};

export const lazyGetIcon = async (category) => {
  let icon;
  switch (category) {
    case "folder":
      icon = await import("../../assets/folderSmall.png");
      // .then((module) => module.default)
      // .catch((error) => null);
      break;
    case "audio":
      icon = await import("../../assets/audioSmall.png");
      // .then((module) => module.default)
      // .catch((error) => null);
      break;
    case "video":
    default:
      icon = await import("../../assets/videoSmall.png");
      // .then((module) => module.default)
      // .catch((error) => null);
      break;
  }
  return icon.default;
};

export const getIcon = (category) => {
  let icon;
  switch (category) {
    case "folder":
      icon = folderSmall;
      break;
    case "audio":
      icon = audioSmall;
      break;
    case "video":
    default:
      icon = videoSmall;
      break;
  }
  return icon;
};

export const useHandleSelect = () => {
  const dispatch = useDispatch();
  const handleSelect = (media) => {
    dispatch(checkboxChange(media));
  };
  const handleSelectAll = () => {
    dispatch(selectDeselectAll(true));
  };
  const handleDeselectAll = () => {
    dispatch(selectDeselectAll(false));
  };
  const handleSelectAllParam = (checked) => {
    dispatch(selectDeselectAll(checked));
  };
  return {
    handleSelect,
    handleSelectAll,
    handleSelectAllParam,
    handleDeselectAll,
  };
};
