import { useCallback, useEffect, useState } from "react";
import JwtDecode from "jwt-decode";
import { momentDateTimeFormat } from "./constants";
import moment from "moment";
import { logout } from "./AuthProvider";

// we take the response from out login request and encode it
export const encodeUn = (un) => {
  var encodedUn = btoa(un);
  return encodedUn;
};

export const setCookie = (cn, exdays, un) => {
  const cookie = encodeUn(un);
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  // we set the expiration date for the cookie which will be 14 days
  var expires = "expires=" + d.toUTCString();
  // we save the time cookie was created in minutes inside the cookie and using d.getMinutes()
  document.cookie =
    cn + "=" + d.getMinutes() + "$" + cookie + ";" + expires + ";path=/";
};
export const getLocalStorage = (item = "", key = "", parse = false) => {
  const storage = localStorage.getItem(item);
  const parsedStorage = parse ? JSON.parse(storage) : storage;
  return key && parsedStorage ? parsedStorage[key] : parsedStorage;
};

export const useModal = (defaultModals = {}) => {
  const [modals, setOpenModals] = useState(defaultModals);
  const setModal = (modal, value) => {
    setOpenModals({ ...modals, [modal]: value });
  };
  const handleClose = (modal) => {
    setOpenModals({ ...modals, [modal]: false });
  };
  const handleOpen = (modal) => {
    setOpenModals({ ...modals, [modal]: true });
  };
  return [modals, setModal, handleClose, handleOpen];
};

export const tokenInfo = (token = "") => {
  if (token) {
    const decoded = JwtDecode(token);
    return decoded;
  }
  return null;
};
export const clearStorageInterval = (key) => {
  const interval = JSON.parse(sessionStorage.getItem(key));

  if (Array.isArray(interval)) {
    for (let index = 0; index < interval.length; index++) {
      clearInterval(interval[index]);
    }
  } else {
    clearInterval(interval);
  }

  sessionStorage.removeItem(key);
};

export const createStorageInterval = (
  key,
  method,
  time = 10,
  milisec = 1000
) => {
  let ids = JSON.parse(sessionStorage.getItem(key)) || [];

  if (Array.isArray(ids)) {
    ids.push(
      setInterval(() => {
        method();
      }, time * milisec)
    );
  } else {
    ids = [
      ids,
      setInterval(() => {
        method();
      }, time * milisec),
    ];
  }

  sessionStorage.setItem(key, JSON.stringify(ids));
};

export const formatDatetime = (
  datetime = "N/A",
  format = momentDateTimeFormat
) => {
  return moment(datetime).isValid()
    ? moment(datetime).format(format)
    : datetime;
};

export const capitalizeString = (string = "") => {
  if (!string) return null;
  const capitalizedFirstLetter = string[0].toUpperCase();
  return capitalizedFirstLetter + string.slice(1);
};

export const useGenerateNumbers = (start, amount, years = false) => {
  const [numbers, setNumbers] = useState(null);
  const generateYears = useCallback(
    (startYear, amountYears = amount) => {
      const startY = startYear || start || parseInt(moment().format("YYYY"));
      const tempYears = [];
      const futureYears = startY + amountYears;
      for (let i = startY; i < futureYears; i++) {
        tempYears.push(i);
      }
      return tempYears;
    },
    [amount, start]
  );
  const generateNumbers = useCallback((start, end) => {
    const tempNumbers = [];
    for (let i = start; i < end; i++) {
      tempNumbers.push(i);
    }
    return tempNumbers;
    // setNumbers(tempNumbers);
  }, []);

  useEffect(() => {
    if (!numbers) {
      if (years) setNumbers(generateYears(start, amount));
      else setNumbers(generateNumbers(start, amount));
    }
    return () => {};
  }, [amount, start, generateNumbers, generateYears, numbers, years]);
  return [numbers, generateNumbers, generateYears];
};

export const generateNumbers = (start, end) => {
  const tempNumbers = [];
  for (let i = start; i < end; i++) {
    tempNumbers.push(i);
  }
  return tempNumbers;
};

export function strip(html) {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText;
}

export function defineRange({ start, end, step }) {
  function* range(start, end, step) {
    while (start < end) {
      yield start;
      start += step;
    }
  }
  return Array.from(range(start, end, step));
}
export const logoutAndRedirect = (message = "", redirect = "/login") => {
  clearStorageInterval("not");
  logout();
  window.location.href = redirect;
  sessionStorage.setItem("error_msg", message);
};

export const checkFolderSpecialChars = (string) => {
  const folderNameRegex = /^[^\\/:*?"<>|]+$/g;
  return folderNameRegex.test(string);
};

export const copyToClipboard = (ref) => {
  ref.select();
  return document.execCommand("copy");
};

export function humanFileSize(bytes, si) {
  var thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  var units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + " " + units[u];
}
export default humanFileSize;
