import React, { useEffect, useState } from "react";
import { Col, NavDropdown, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import Connection from "../../../utils/Connection";

import NotificationImage from "../../../assets/NotificationImage.svg";
import { useDispatch } from "react-redux";
import { setError } from "../../SplashPage/SplashPageSlice";
import {
  clearStorageInterval,
  createStorageInterval,
  strip,
} from "../../../utils/Index";

const NavBarNotifications = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  const getNotifications = async () => {
    const conn = new Connection();
    await conn.get("/user/notifications/");

    if (conn.getStatusCode() === 200) {
      const response = conn.getData();
      setNotificationCount(response?.data?.length || 0);
      setNotifications(response?.data);
    }
  };

  const setNotificationsRead = async (notificationId) => {
    const conn = new Connection();
    await conn.create("/user/notifications/read/", {
      notification_id: notificationId,
    });

    const response = conn.getResponse();
    if (response.status.code === 200) {
      setNotificationCount(response.data.length);
      setNotifications(response.data.data);
    } else dispatch(setError(response.data?.message));
  };

  const setDismissAllNotifications = async (e) => {
    e.preventDefault();
    const conn = new Connection();
    await conn.create("/user/notifications/read/all/", {});

    const response = conn.getResponse();
    if (response.status.code === 200) {
      setNotificationCount(0);
      setNotifications(response.data.data);
    } else dispatch(setError(response.data?.message));
  };

  const handleUrl = (notificationid, url = "/") => {
    setNotificationsRead(notificationid);
    history.push(url);
  };

  const NotificationComponent = () => {
    return (
      <Row noGutters className="justify-content-end">
        <Col xs="auto">
          <img
            src={NotificationImage}
            alt="User Profile"
            className="navbar-icons"
          />
        </Col>
        <Col xs="auto">
          {notificationCount === 0 ? (
            ""
          ) : (
            <span className="navnotifications-count">{notificationCount}</span>
          )}
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    getNotifications();
    window.document.title = notifications.length
      ? `(${notifications.length}) AIMA`
      : "AIMA";

    clearStorageInterval("not");
    createStorageInterval("not", getNotifications, 10);
  }, [notifications.length]);

  return (
    <NavDropdown
      id="navnotifications"
      className="navnotifications"
      renderMenuOnMount
      title={NotificationComponent()}
      menuRole="container"
      alignRight
    >
      <div className="navnotifications-item-sticky">
        <NavDropdown.Item className="navnotifications-item navnotifications-item-heading">
          <Row>
            <Col></Col>
            <Col className="text-center">
              {notifications.length
                ? "Notifications"
                : "No notifications to display"}
            </Col>
            <Col className="navnotifications-item-dismiss text-right">
              {notificationCount ? (
                <button
                  className="link-button"
                  onClick={(e) => setDismissAllNotifications(e)}
                >
                  Dismiss all
                </button>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </NavDropdown.Item>
        <NavDropdown.Divider className="navbar-divider" />
      </div>
      {notifications.map((key, i) => (
        <React.Fragment key={i}>
          {i !== 0 && <NavDropdown.Divider className="navbar-divider" />}
          <NavDropdown.Item
            title={strip(key.title)}
            className="navnotifications-item"
            onClick={() => handleUrl(key.notificationid, key.url)}
            dangerouslySetInnerHTML={{ __html: key.title }}
          />
        </React.Fragment>
      ))}
    </NavDropdown>
  );
};

export default NavBarNotifications;
