import { createSlice } from "@reduxjs/toolkit";

export const transcriptSlice = createSlice({
  name: "transcript",
  initialState: {
    editIsActive: false,
    transcriptSpeakers: [],
    updatedTranscript: [],
    newSpeakers: [],
    searchTerm: "",
  },
  reducers: {
    transcriptSpeakers: (state, action) => {
      state.transcriptSpeakers = action.payload;
    },
    toggleEditMode: (state, action) => {
      state.editIsActive = action.payload;
    },
    transcriptReadable: (state, action) => {
      let mainArray = [];
      //delcare new sub array
      let subArray = [];

      //loop through array
      for (let i = 0; i < action.payload.length; i++) {
        if (action.payload[i + 1]) {
          if (action.payload[i].speaker === action.payload[i + 1].speaker) {
            subArray.push(action.payload[i].word);
            // debugger;
          } else {
            subArray.push(action.payload[i].word);
            subArray.push(action.payload[i].speaker);
            mainArray.push(subArray);
            subArray = [];
          }
        } else {
          subArray.push(action.payload[i].word);
          subArray.push(action.payload[i].speaker);
          mainArray.push(subArray);
        }
      }

      //setup for creating new Array

      let newArray = [];
      //Create array for readable text with speakers as key
      mainArray.forEach((array, index) => {
        let obj = {};
        obj["speaker"] = array[array.length - 1];
        obj["text"] = array.slice(0, array.length - 1).join(" ");
        newArray.push(obj);
      });
      state.readAbleTexts = newArray;
    },
    createOldAndUpdatedTrans: (state, action) => {},
    setUpdatedTranscript: (state, action) => {
      state.updatedTranscript = action.payload;
    },

    setNewSpeakers: (state, action) => {
      state.newSpeakers = [...state.newSpeakers, action.payload];
    },
    setVideoId: (state, action) => {
      state.video_id = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setActiveSearch: (state, action) => {
      state.searchActive = action.payload;
    },
    clearData: (state, action) => {
      state.updatedTranscript = action.payload;
      state.transcriptSpeakers = action.payload;
      state.editIsActive = false;
      state.readAbleTexts = action.payload;
      state.searchTerm = "";
      state.searchActive = action.false;
    },
    setVideoTime: (state, action) => {
      state.videoTime = action.payload;
    },
  },
});

export const {
  transcriptSpeakers,
  toggleEditMode,
  transcriptReadable,
  createOldAndUpdatedTrans,
  setUpdatedTranscript,
  setVideoId,
  setSearchTerm,
  setActiveSearch,
  clearData,
  setNewSpeakers,
  setVideoTime,
} = transcriptSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const createSpeakerArray = (array) => async (dispatch) => {
  dispatch(transcriptSpeakers(array));
};

export const toggleEdit = (payload) => async (dispatch) => {
  dispatch(toggleEditMode(payload));
};

export const createReadable = (array) => async (dispatch) => {
  dispatch(transcriptReadable(array));
};

export const compareTranscript = (oldTrans, newTrans) => async (dispatch) => {
  dispatch(createOldAndUpdatedTrans(oldTrans, newTrans));
};

export const setUpdatedArray = (trans) => async (dispatch) => {
  dispatch(setUpdatedTranscript(trans));
};

export const setUpdatedSpeakers = (speakers) => async (dispatch) => {
  dispatch(setUpdatedSpeakers(speakers));
};

export const setTranscriptSearchTerm = (term) => async (dispatch) => {
  dispatch(setSearchTerm(term));
};

export const toggleSearchTerms = (payload) => async (dispatch) => {
  dispatch(setActiveSearch(payload));
};

export const clearTranscriptData = (payload) => async (dispatch) => {
  dispatch(clearData(payload));
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export default transcriptSlice.reducer;
