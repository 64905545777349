import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useAuth } from "./utils/AuthProvider";
import { tokenInfo } from "./utils/Index";
import LazyLoad from "./features/ReusableComponents/LazyLoad/LazyLoad";
import { useDispatch } from "react-redux";
import {
  asyncUserPermissions,
  clearUserInfo,
} from "./features/settings/SettingsSlice";

const SplashPage = React.lazy(() => import("./features/SplashPage/SplashPage"));
const Success = React.lazy(() => import("./features/Payment/Success/Success"));
const Cancel = React.lazy(() => import("./features/Payment/Cancel/Cancel"));

const MediaResults = React.lazy(() =>
  import("./features/MediaResults/MediaResults")
);
const Settings = React.lazy(() => import("./features/settings/Settings"));
const Billing = React.lazy(() => import("./features/settings/Billing/Billing"));
const NotFound = React.lazy(() => import("./features/NotFound/NotFound"));
const MainLogin = React.lazy(() => import("./features/mainLogin/MainLogin"));
const ConfirmUser = React.lazy(() =>
  import("./features/mainLogin/ConfirmUser/ConfirmUser")
);

const ForgotPassword = React.lazy(() =>
  import("./features/mainLogin/forgotpassword/ForgotPassword")
);
const Plans = React.lazy(() => import("./features/Payment/Plans/Plans"));
const ChangePlan = React.lazy(() =>
  import("./features/Payment/ChangePlan/ChangePlan")
);
const LandingPage = React.lazy(() =>
  import("./features/LandingPage/LandingPage")
);
const TrialSuccess = React.lazy(() =>
  import("./features/Payment/Plans/TrialSuccess/TrialSuccess")
);
const AdminPage = React.lazy(() => import("./features/AdminPage/AdminPage"));

const ValidatePasswordHash = React.lazy(() =>
  import("./features/mainLogin/forgotpassword/ValidatePasswordHash")
);

const PlansInfo = React.lazy(() =>
  import("./features/LandingPage/PlansInfo/PlansInfo")
);

const ResourceContent = React.lazy(() =>
  import("./features/ResourceContent/ResourceContent")
);

const PrivacyPolicy = React.lazy(() =>
  import("./features/PrivacyPolicy/PrivacyPolicy")
);

const TermsOfService = React.lazy(() =>
  import("./features/TermsOfService/TermsOfService")
);

// const RecoveryLinkPage = React.lazy(() =>
//   import("./features/auth/recoveryLinkPage")
// );
// const Trash = React.lazy(() => import("./features/Trash/Trash"));

const Routes = () => {
  const [logged] = useAuth();
  const dispatch = useDispatch();

  const plan = tokenInfo(localStorage.getItem("REACT_TOKEN_AUTH_KEY"));

  const expired = plan?.plan_details?.plan_expired;
  const is_trial = plan?.plan_details?.plan_is_trial;
  var goToPlan = false;

  if (expired === true) {
    goToPlan = true;
  } else if (expired === null && is_trial === null) {
    goToPlan = true;
  }

  const PrivateRoute = ({ component: Component, ...rest }) => {
    useEffect(() => {
      dispatch(asyncUserPermissions());
      return () => dispatch(clearUserInfo());
    }, []);

    return (
      <>
        <Route
          {...rest}
          render={(props) =>
            logged ? <Component {...props} /> : <Redirect to="/login" />
          }
        />
        {goToPlan ? <Redirect to={{ pathname: "/plans" }} /> : ""}
      </>
    );
  };

  return (
    <LazyLoad>
      <Switch>
        <Route exact path="/forgotpassword">
          <ForgotPassword />
        </Route>
        <Route exact path="/redirect">
          <TrialSuccess />
        </Route>

        <Route exact path="/validateResetPassword/:id">
          <ValidatePasswordHash />
        </Route>
        <Route exact path="/confirm-user/:hash" component={ConfirmUser} />
        <Route path="/page-not-found" component={NotFound} />
        <Route exact path="/plans/info" component={PlansInfo} />
        <Route path="/payments/success" component={Success} />
        <Route path="/payments/cancel" component={Cancel} />
        <Route exact path="/terms-of-service">
          <TermsOfService />
        </Route>
        <Route exact path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <PrivateRoute exact path="/plans" component={Plans} />
        <Route path="/share/:hash(.*)">
          <ResourceContent />
        </Route>
        <PrivateRoute
          exact
          path={["/*/result/:id", "/result/:id"]}
          component={MediaResults}
        />
        <PrivateRoute exact path="/admin" component={AdminPage} />
        <PrivateRoute
          exact
          path="/settings/billing/:method"
          component={Billing}
        />
        <PrivateRoute exact path="/settings" component={Settings} />
        {/* <PrivateRoute exact path="/trash" component={Trash} /> */}
        {/* <PrivateRoute
          exact
          path="/recoverylinkpage/:id"
          children={RecoveryLinkPage}
        /> */}
        <PrivateRoute exact path="/change-plan" component={ChangePlan} />
        <Route exact path="/login">
          {logged ? <Redirect to="/" /> : <MainLogin />}
        </Route>
        {logged ? (
          <PrivateRoute path="/" component={SplashPage} />
        ) : (
          <Route exact path="/" component={LandingPage} />
        )}
      </Switch>
    </LazyLoad>
  );
};

export default Routes;
