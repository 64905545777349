import { createSlice } from "@reduxjs/toolkit";
import Connection from "../../../utils/Connection";

export const MediaTabsSlice = createSlice({
  name: "mediaTabsInfo",
  initialState: {
    metaInfo: null,
    initMetaInfo: null,
    changesMessage: null,
  },
  reducers: {
    getMetaInfo: (state, action) => {
      state.metaInfo = action.payload.metadata;
      state.initMetaInfo = action.payload.metadata;
    },
    resetMetaInfo: (state) => {
      state.metaInfo = {};
      state.initMetaInfo = {};
    },
    changesMessage: (state, action) => {
      state.changesMessage = {
        type: action.payload.isError ? "warnning" : "success",
        message: action.payload.message,
      };
    },
    setMetaInfo: (state, action) => {
      state.metaInfo = action.payload;
    },
    setHandleMetadataChange: (state, action) => {
      state.metaInfo[action.payload.name] = action.payload.value;
    },
  },
});

export const {
  getMetaInfo,
  changesMessage,
  setMetaInfo,
  setHandleMetadataChange,
  resetMetaInfo,
} = MediaTabsSlice.actions;

export const asyncGetFileMetadata = (file_id) => async (dispatch) => {
  const conn = new Connection();
  try {
    const response = await conn.create("/media/metadata/", { file_id });
    if (conn.getStatusCode() === 200) {
      dispatch(getMetaInfo(response.data));
    } else {
      dispatch(getMetaInfo({ metadata: {} }));
      dispatch(changesMessage(response.data));
    }
  } catch (error) {
    console.log(error);
    dispatch(getMetaInfo({ metadata: {} }));
    dispatch(changesMessage(error.toString()));
  }
};

export const asyncChangeFileMetadata = (metadata) => async (dispatch) => {
  const conn = new Connection();

  const response = await conn.create("/media/metadata/change/", metadata);

  if (conn.getStatusCode() === 200) {
    dispatch(getMetaInfo({ metadata }));
  }

  dispatch(changesMessage(response.data));
};

export default MediaTabsSlice.reducer;
