import { createAuthProvider } from "react-token-auth";

const host = process.env.REACT_APP_BACKEND_HOST;
const port = process.env.REACT_APP_BACKEND_PORT;
const api = process.env.REACT_APP_BACKEND_API;
export const [useAuth, authFetch, login, logout] = createAuthProvider({
  accessTokenKey: "access_token",
  onUpdateToken: (token) =>
    fetch(`${host}${port}${api}/user/refresh/`, {
      method: "POST",
      body: token.access_token,
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem("REACT_TOKEN_AUTH_KEY");
      }

      return response.json();
    }),
});
