import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import loader from "../../../assets/loader.gif";
const Loading = ({ width, height, position }) => {
  let className = "justify-content-md-";
  switch (position) {
    case "start":
      className += "start";
      break;
    case "end":
      className += "end";
      break;
    case "center":
    default:
      className += "center";
      break;
  }
  return (
    <Container>
      <Row className={className}>
        <Col sm="auto">
          <img
            src={loader}
            alt="Loader"
            width={width || "200"}
            height={height || "202"}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Loading;
