import { createSlice } from "@reduxjs/toolkit";

import Connection from "../../utils/Connection";

export const SplashPageSlice = createSlice({
  name: "media",
  initialState: {
    pathname: "/",
    parent_id: null,
    selectedFiles: [],
    selectedAllMedia: false,
    newMediaFiles: false,
    inProgressMedia: [],
    analyzingMedia: false,
    mediaList: null,
    mediaForPreview: null,
    allowedFileTypes: null,
    breadcrumb: [{ label: "Home", path: "/" }],
    deletedMediaList: null,
    folder_id: null,
    share: {
      email: "",
      emails: [],
      comments: "",
      url: "",
    },
    display: {
      notifications: true,
      sidebar: true,
      account: true,
    },
    search: {
      isSearched: false,
      files: null,
      searchWord: "",
    },
    image: {
      base64: "",
      content_type: "",
      alt: "Preview",
    },
    error: {
      exist: false,
      message: "",
      variant: "danger",
      techError: "",
    },
    isLoading: false,
  },
  reducers: {
    setInProgressMedia: (state, action) => {
      //set files in progress

      //detect if there are new in progress files
      state.newMediaFiles =
        action.payload.length < state.inProgressMedia.length;
      state.inProgressMedia = action.payload;
      state.analyzingMedia = action.payload?.length ? true : false;
    },

    resetInProgressMedia: (state) => {
      //set files in progress

      //update only there are more
      // if (action.payload.length === 0 && state.inProgressMedia.length !== 0) {
      //   state.inProgressMedia = [];
      // } else if (
      //   action.payload.length === 0 &&
      //   state.inProgressMedia.length === 0
      // ) {
      //   return;
      // }

      //detect if there are new in progress files
      state.newMediaFiles = false;
      state.inProgressMedia = [];
      state.analyzingMedia = false;
    },
    addInProgressMedia: (state, action) => {
      //set files in progress
      state.inProgressMedia = [...action.payload, ...state.inProgressMedia];
      state.analyzingMedia = action.payload?.length ? true : false;
    },
    removeInProgressMedia: (state, action) => {
      //set files in progress
      const file_id = action.payload;
      const inProgressMedia = state.inProgressMedia.filter(
        (media) => media.file_id !== file_id
      );
      state.inProgressMedia = inProgressMedia;
      state.analyzingMedia = inProgressMedia.length ? true : false;
    },
    renameMediaForPreview: (state, action) => {
      //set files in progress
      state.mediaForPreview = {
        ...state.mediaForPreview,
        filename: action.payload,
      };
    },
    setProgressToFile: (state, action) => {
      //set the progress when uploading to the backend
      const { progress, file_id } = action.payload;
      state.inProgressMedia = state.inProgressMedia?.map((media) => ({
        ...media,
        progress: media.file_id === file_id ? progress : media.progress,
      }));
    },
    setMediaList: (state, action) => {
      //set the users files
      state.mediaList = action.payload;
    },
    resetMediaList: (state) => {
      //set the users files
      state.mediaList = null;
    },
    setPathname: (state, action) => {
      //set the users files
      state.pathname = action.payload.pathname;
      state.parent_id = action.payload.parent_id;
    },
    setSidebarDisplay: (state, action) => {
      //set the users files
      state.display.sidebar = action.payload;
    },
    setNotificationsDisplay: (state, action) => {
      //set the users files
      state.display.notifications = action.payload;
    },
    setAccountDisplay: (state, action) => {
      //set the users files
      state.display.account = action.payload;
    },
    selectFromMediaList: (state, action) => {
      //mark an element true from the list array
      const file_sec = action.payload;
      const foundIndex = state.mediaList.findIndex(
        (media) => media.file_sec === file_sec
      );
      const foundMedia = state.mediaList[foundIndex];
      state.mediaList[foundIndex] = {
        ...foundMedia,
        checked: !foundMedia.checked,
      };
      // state.mediaList = action.payload
    },
    selectDeselectAll: (state, action) => {
      //mark all element true or false
      const checked = action.payload;
      state.mediaList = state.mediaList.map((media) => ({ ...media, checked }));
      state.selectedAllMedia = checked;
    },
    setDeletedMediaList: (state, action) => {
      //set the users files
      state.deletedMediaList = action.payload;
    },
    setBreadcrumb: (state, action) => {
      //set the users files
      state.breadcrumb = action.payload;
    },
    unshiftToMediaList: (state, action) => {
      // push elements to the start of the media list
      const newMediaList = [...action.payload, ...state.mediaList];
      state.mediaList = newMediaList;
    },
    setMediaForPreview: (state, action) => {
      // set the element to be as a preview thumbnail
      state.mediaForPreview = action.payload;
    },
    setAllowedFileTypes: (state, action) => {
      //set the file types allowed to upload
      state.allowedFileTypes = action.payload;
    },
    setImage: (state, action) => {
      //set the preview image for thumbnail
      state.image = { ...state.image, ...action.payload };
    },
    resetImage: (state) => {
      //set the preview image for thumbnail
      state.image = {
        base64: "",
        blob: null,
        content_type: "",
        alt: "Preview",
      };
    },
    changeThumbnail: (state, action) => {
      //set the preview image for thumbnail
      const { file_id, image } = action.payload;
      state.mediaList = state.mediaList.map((file) => ({
        ...file,
        thumbnail: file.file_id === file_id ? image : file.thumbnail,
      }));
    },
    setFolderId: (state, action) => {
      //set the folder id whenever it changes
      // if (state.currentFolder !== action.payload) {
      //   state.previousFolder = state.currentFolder;
      // }
      state.folder_id = action.payload;
    },
    setSearchedFiles: (state, action) => {
      // set the files being searched for
      state.search.files = action.payload.files;
      state.search.searchWord = action.payload.search_word;
      state.search.isSearched = true;
    },
    changeSearchedFiles: (state, action) => {
      // set the files being searched for
      state.search.files = action.payload;
    },
    setIsSearched: (state, action) => {
      // set the files being searched for
      state.search.isSearched = action.payload;
    },
    resetState: (state, action) => {
      // set the files being searched for
      state.inProgressMedia = [];
      state.analyzingMedia = false;
      state.mediaForPreview = null;
      state.mediaList = null;
      state.search = {
        isSearched: false,
        files: null,
        searchWord: "",
      };
      state.display = {
        notifications: true,
        sidebar: true,
        account: true,
      };
      state.currentFolder = null;
    },
    setAlert: (state, action) => {
      const { exist, message, variant } = action.payload;
      state.error = {
        exist: exist || true,
        message,
        variant: variant || "danger",
      };
    },
    setError: (state, action) => {
      const message = action.payload;
      state.error = { exist: true, message, variant: "danger" };
    },
    setTechError: (state, action) => {
      const techError = action.payload;
      state.error.techError = techError;
    },
    setInfo: (state, action) => {
      const message = action.payload;
      state.error = { exist: true, message, variant: "info" };
    },
    setSuccess: (state, action) => {
      const message = action.payload;
      state.error = { exist: true, message, variant: "success" };
    },
    setWarning: (state, action) => {
      const message = action.payload;
      state.error = { exist: true, message, variant: "warning" };
    },
    clearError: (state) => {
      state.error = { exist: false, message: "", variant: "danger" };
    },

    checkAll: (state, action) => {
      //check all files in the list view
      const checked = action.payload || false;
      if (checked) {
        state.selectedFiles = state.mediaList.map((media) => ({
          file_id: media.file_id,
          file_sec: media.file_sec,
          file_type_id: media.file_type_id,
          checked,
        }));
      } else state.selectedFiles = [];
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    setIsNotLoading: (state) => {
      state.isLoading = false;
    },

    checkboxChange: (state, action) => {
      // get values from payload
      const { file_sec } = action.payload;
      const isSearched = state.search.isSearched;
      const filters = (media) => media.file_sec === file_sec;

      if (isSearched === true) {
        const foundIndex = state.search.files.findIndex(filters);
        state.search.files[foundIndex].checked =
          !state.search.files[foundIndex].checked;
      } else {
        const foundIndex = state.mediaList.findIndex(filters);
        state.mediaList[foundIndex].checked =
          !state.mediaList[foundIndex].checked;
      }
    },
    setShareResource: (state, action) => {
      state.share = action.payload;
    },
    setShareInfo: (state, action) => {},
  },
});

export const {
  setInProgressMedia,
  setMediaList,
  resetMediaList,
  setBreadcrumb,
  setMediaForPreview,
  setAllowedFileTypes,
  unshiftToMediaList,
  setImage,
  resetImage,
  changeThumbnail,
  setSearchedFiles,
  setIsSearched,
  addInProgressMedia,
  resetState,
  setProgressToFile,
  setError,
  setAccountDisplay,
  setNotificationsDisplay,
  setSidebarDisplay,
  setInfo,
  setAlert,
  setSuccess,
  setWarning,
  clearError,
  setDeletedMediaList,
  selectFromMediaList,
  selectDeselectAll,
  checkboxChange,
  checkAll,
  setFolderId,
  renameMediaForPreview,
  resetInProgressMedia,
  setPathname,
  changeSearchedFiles,
  removeInProgressMedia,
  setTechError,
  setIsLoading,
  setLoading,
  setIsNotLoading,
  setShareResource,
  setShareInfo,
} = SplashPageSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const asyncGetInProgressMedia = () => async (dispatch, getState) => {
  const state = getState();
  const inProgressMedia = state.media.inProgressMedia;
  const pathname = state.media.pathname;
  const parent_id = state.media?.parent_id;
  const conn = new Connection();
  const response = await conn.create("/media/list/progress/", {});
  if (conn.getResponse().status.code === 200) {
    //if there is media in progress
    if (response.data?.length) {
      //if there are not more media en progress but the state is not being cleaned up
      dispatch(setInProgressMedia(response.data));
    } else if (response.data?.length === 0 && inProgressMedia.length !== 0) {
      dispatch(resetInProgressMedia());
      dispatch(asyncGetMediaList(pathname, parent_id));
    }
  } else {
    dispatch(setError(conn.getResponse().data?.message));
    dispatch(setTechError(conn.getResponse().data?.error));
  }
};
export const asyncGetMediaList =
  (pathname = "/", parent_id = null) =>
  async (dispatch) => {
    const conn = new Connection();
    // const response =await conn.create("/media/list/v2/", { file_id });
    dispatch(resetMediaList());
    const response = await conn.create("/media/list/v2/", {
      pathname,
      parent_id,
    });

    if (conn.getResponse().status.code === 200) {
      dispatch(setFolderId(response.data.folder_id));
      dispatch(setMediaList(response.data.allFiles));
      dispatch(setMediaForPreview(response.data.lastFile));
      dispatch(setBreadcrumb(response.data.breadcrumb));
    } else {
      dispatch(setError(conn.getResponse().data?.message));
      dispatch(setTechError(conn.getResponse().data?.error));
    }
  };
export const asyncGetDeletedMediaList = () => async (dispatch) => {
  const conn = new Connection();
  // const response =await conn.create("/media/list/v2/", { file_id });
  const response = await conn.create("/media/list/v2/", {
    is_deleted: true,
  });
  if (conn.getResponse().status.code === 200) {
    dispatch(setDeletedMediaList(response.data.allFiles));
    dispatch(setBreadcrumb([{ label: "Trash", path: "/trash" }]));
  } else {
    dispatch(setError(conn.getResponse().data?.message));
    dispatch(setTechError(conn.getResponse().data?.error));
  }
  // if (response.status.code) dispatch(setCurrentFolder(pathname));
};
export const asyncSearchMedia = (search_word) => async (dispatch) => {
  const conn = new Connection();
  const response = await conn.create("/media/search/results/", { search_word });
  if (conn.getResponse().status.code === 200) {
    dispatch(setSearchedFiles({ files: response.data.allFiles, search_word }));
  } else {
    dispatch(setError(conn.getResponse().data?.message));
    dispatch(setTechError(conn.getResponse().data?.error));
  }
};
export const asyncGetThumbnail = (file_id) => async (dispatch) => {
  const conn = new Connection();
  const response = await conn.get(`/media/thumbnail-video/${file_id}`);
  if (conn.getResponse().status.code === 200) {
    dispatch(changeThumbnail({ file_id, image: response.data.data }));
  } else {
    dispatch(setError(conn.getResponse().data?.message));
    dispatch(setTechError(conn.getResponse().data?.error));
  }
};
export const asyncGetFileInfo = (file_id) => async (dispatch) => {
  const conn = new Connection();
  await conn.get(`/media/info/${file_id}`);
};

export const asyncGetAllowedFileTypes = () => async (dispatch) => {
  const conn = new Connection();
  const response = await conn.get("/media/get/filetypes/");
  const parsedFileTypes = response.data.file_types.reduce((acc, type) => {
    acc += type + ", ";
    return acc;
  }, "");
  if (conn.getResponse().status.code === 200) {
    dispatch(setAllowedFileTypes(parsedFileTypes));
  } else {
    dispatch(setError(conn.getResponse().data?.message));
    dispatch(setTechError(conn.getResponse().data?.error));
  }
};

// export const changeChecked = (payload) => async (dispatch) => {
//   dispatch(checkboxChange(payload));
// };

// export const changeChecked = (state = {}, action) => {
//   const { payload, type } = action;
//   const { id, checked } = payload;
//   return {
//     ...state,
//     type,
//     files: state.files.map((file) =>
//       file.absolute_path === id ? { ...file, checked } : { ...file }
//     ),
//   };
// };
export const selectedFilesSelector = (state) => {
  if (state.media.search.isSearched === true) {
    return Array.isArray(state.media.search.files)
      ? state.media.search.files.filter((file) => file.checked)
      : null;
  } else {
    return Array.isArray(state.media.mediaList)
      ? state.media.mediaList.filter((file) => file.checked)
      : null;
  }
};
export const selectedFilesLengthSelector = (state) => {
  if (state.media.search.isSearched === true) {
    return Array.isArray(state.media.search.files)
      ? state.media.search.files.filter((file) => file.checked).length
      : null;
  } else {
    return Array.isArray(state.media.mediaList)
      ? state.media.mediaList.filter((file) => file.checked).length
      : null;
  }
};
export const isLoadingSelector = (state) => state.media.isLoading;

export const searchFiles = (search) => async (dispatch) => {
  dispatch(setIsLoading());
  const conn = new Connection();
  await conn.create("/media/search/results/", {
    search_word: search,
  });

  if (conn.getStatusCode() === 200) {
    dispatch(
      setSearchedFiles({
        files: conn.getResponse().data.allFiles,
        search_word: search,
      })
    );
  } else {
    dispatch(setError(conn.getResponse().data?.message));
    dispatch(setTechError(conn.getResponse().data?.error));
  }
  dispatch(setIsNotLoading());
};

export const getShareResourceInfo = (media_id) => async (dispatch) => {
  const conn = new Connection();
  await conn.create("/media/get/share/resource/", {
    resource_id: media_id,
  });

  if (conn.getStatusCode() === 200) {
    return dispatch(setShareResource(conn.getData().data));
  } else {
    dispatch(setError(conn.getResponse().data?.message));
    dispatch(setTechError(conn.getResponse().data?.error));
  }
};

export const asyncShareResource = (share_info) => async (dispatch) => {
  const conn = new Connection();
  await conn.create("/media/send/share/resource/", share_info);

  if (conn.getStatusCode() === 200) {
    return dispatch(setShareResource(conn.getData().data));
  } else {
    dispatch(setError(conn.getResponse().data?.message));
    dispatch(setTechError(conn.getResponse().data?.error));
  }
};

export const asyncUnShareResource = (share_info) => async (dispatch) => {
  const conn = new Connection();
  await conn.create("/media/unshare/resource/", share_info);

  if (conn.getStatusCode() === 200) {
    return dispatch(
      setAlert({ exist: true, ...conn.getData().data, variant: "success" })
    );
  } else {
    dispatch(setError(conn.getResponse().data?.message));
    dispatch(setTechError(conn.getResponse().data?.error));
  }
};
export const asyncCancelUpload = (media) => async (dispatch) => {
  const conn = new Connection();
  try {
    const response = await conn.create("/media/upload/cancel/", media);
    dispatch(resetInProgressMedia());
    return dispatch(
      setAlert({
        exist: true,
        message: response.data.message,
        variant: "success",
      })
    );
  } catch (error) {
    dispatch(setError(conn.getResponse().data?.message));
    dispatch(setTechError(conn.getResponse().data?.error));
  }
};

export default SplashPageSlice.reducer;
