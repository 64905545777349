import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import HomeImage from "../../assets/HomeImage.svg";
import CreateFolderImage from "../../assets/CreateFolderImage.svg";
// import MediaImage from "../../assets/MediaImage.svg";
import ClockImage from "../../assets/ClockImage.svg";
// import TrashImage from "../../assets/TrashImage.svg";
import FileStatus from "./filesStatus/FilesStatus";

import "../sidebar/Sidebar.scss";
import CreateFolderModal from "../ReusableComponents/CreateFolderModal/CreateFolderModal";
import { useSelector } from "react-redux";

const Sidebar = (props) => {
  const history = useHistory();
  const [collapse, setCollapse] = useState({ display: "none" });
  const [expand, setExpand] = useState("");
  const [expandButton, setExpandButton] = useState(">>");
  const [analisysProgress, setAnalysisProgress] = useState(false);
  const [createFolderIsOpen, setCreateFolderIsOpen] = useState(false);
  const isSidebarAllowed = useSelector((state) => state.media.display.sidebar);

  const expandMenu = (e, expand_fl) => {
    if (expand_fl === "") {
      setCollapse({ display: "inline-block", height: "100%" });
      setExpandButton("<<");
      setExpand("sidebar-expand");
    } else {
      setCollapse({ display: "none" });
      setExpandButton(">>");
      setExpand("");
      setAnalysisProgress(false);
    }
  };

  const showFilesStatus = (e) => {
    if (expand === "") {
      expandMenu(e, expand);
      setAnalysisProgress(true);
    } else {
      if (analisysProgress === true) {
        setAnalysisProgress(false);
      } else {
        setAnalysisProgress(true);
      }
    }
  };

  const handleHome = (e) => {
    history.push("/");
  };

  return isSidebarAllowed ? (
    <>
      <Nav className={"d-md-block sidebar " + expand}>
        <Row style={{ height: "100%" }}>
          <Col
            className="sidebar-menu-column-a"
            sm={expand === "" ? {} : { span: 12 }}
          >
            <Nav.Item className="sidebar-menu" onClick={(e) => handleHome(e)}>
              <img
                className="sidebar-menu-image"
                src={HomeImage}
                alt="my-media"
              />
              <div style={collapse} className="sidebar-menu-label">
                Home
              </div>
            </Nav.Item>
            <Nav.Item
              className="sidebar-menu"
              onClick={() => setCreateFolderIsOpen(true)}
            >
              <img
                className="sidebar-menu-image"
                src={CreateFolderImage}
                alt="files-status"
              />
              <div style={collapse} className="sidebar-menu-label">
                Create Folder
              </div>
            </Nav.Item>
            <Nav.Item
              className="sidebar-menu"
              onClick={(e) => showFilesStatus(e)}
            >
              <img
                className="sidebar-menu-image"
                src={ClockImage}
                alt="files-status"
              />
              <div style={collapse} className="sidebar-menu-label">
                Upload Status
              </div>
              {analisysProgress ? <FileStatus /> : ""}
            </Nav.Item>
            <Nav.Item>
              <div
                className="sidebar-expand-button"
                onClick={(e) => expandMenu(e, expand)}
              >
                {expandButton}
              </div>
            </Nav.Item>
          </Col>
        </Row>
      </Nav>
      {createFolderIsOpen && (
        <CreateFolderModal
          isOpen={createFolderIsOpen}
          handleClose={() => setCreateFolderIsOpen(false)}
        />
      )}
    </>
  ) : (
    ""
  );
};
export default Sidebar;
