import React from "react";
import { Suspense } from "react";
import Loading from "../Loading/Loading";

const LazyLoad = ({ children, height = "300px", width = "300px" }) => {
  return (
    <Suspense fallback={<Loading height={height} width={width} />}>
      {children}
    </Suspense>
  );
};

export default LazyLoad;
