import React, { useEffect } from "react";
import { Col, Form, ProgressBar } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { asyncGetInProgressMedia } from "../../SplashPage/SplashPageSlice";

import "./FileStatus.scss";

const FilesStatus = (props) => {
  const dispatch = useDispatch();
  const inProgressMedia = useSelector((state) => state.media.inProgressMedia);

  useEffect(() => {
    dispatch(asyncGetInProgressMedia());
  }, [dispatch]);

  return (
    <div
      className="filestatus"
      style={inProgressMedia.length === 0 ? { display: "none" } : {}}
    >
      {inProgressMedia.map((i, key) => (
        <Form.Group key={key} className="filestatus-section">
          <Col sm={{ span: 12 }}>
            <ProgressBar
              className="filestatus-section-progress"
              now={i.progress}
              label={`${i.progress}%`}
            />
          </Col>
          <Col sm={{ span: 12 }}>
            <div className="filestatus-section-label">
              Media: "{i.filename}"
            </div>
          </Col>
        </Form.Group>
      ))}
    </div>
  );
};

export default FilesStatus;
