import { createSlice } from "@reduxjs/toolkit";
import Connection from "../../utils/Connection";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    userInfo: {
      admin: false,
      firstname: "",
      lastname: "",
      company: "",
      phone: "",
      plan_details: null,
      plan_permissions: null,
    },
    resetUserInfo: {},
    changesMessage: null,
  },
  reducers: {
    getUserAccountInfo: (state, action) => {
      if (action.payload) {
        state.userInfo = action.payload;
        state.resetUserInfo = action.payload;
      }
    },
    setUserAccountInfo: (state) => {
      state.userInfo = state.resetUserInfo;
    },
    setHandleChange: (state, action) => {
      state.userInfo[action.payload.name] = action.payload.value;
    },
    changePassword: (state, action) => {
      state.changesMessage = action.payload;
    },
    editAccount: (state, action) => {
      state.changesMessage = action.payload;
    },
    getPlanAutomaticCharges: (state, action) => {
      state.userInfo.plan_details.charge_automatically = action.payload;
    },
    changesError: (state, action) => {
      state.changesMessage = action.payload;
    },
    clearUserInfo: (state) => {
      state.userInfo = {
        firstname: "",
        lastname: "",
        company: "",
        phone: "",
        plan_details: null,
        plan_permissions: null,
      };
      state.resetUserInfo = {};
    },
  },
});

export const {
  getUserAccountInfo,
  setUserAccountInfo,
  setHandleChange,
  changePassword,
  editAccount,
  changesError,
  clearUserInfo,
  getPlanAutomaticCharges,
} = settingsSlice.actions;

export const asyncUserInfo = () => async (dispatch) => {
  const conn = new Connection();

  const response = await conn.create("/user/info/");

  if (conn.getStatusCode() === 200) {
    dispatch(getUserAccountInfo(response.data.userInfo));
  } else {
    dispatch(changesError(response));
  }
};

export const asyncEditAccount = (userInfo) => async (dispatch) => {
  const conn = new Connection();
  const response = await conn.create("/user/change/account/", userInfo);

  if (conn.getStatusCode() === 200) {
    dispatch(getUserAccountInfo(response.data.userInfo));
    dispatch(
      editAccount({
        isError: response.data.isError,
        message: response.data.message,
      })
    );
  } else {
    dispatch(changesError(response.data.message));
  }
};

export const asyncChangePassword = (info) => async (dispatch) => {
  const conn = new Connection();

  const response = await conn.create("/user/change/password/", info);

  if (conn.getStatusCode() === 200) {
    dispatch(
      changePassword({
        isError: response.data.isError,
        message: response.data.message,
      })
    );
  } else {
    dispatch(
      changesError({
        isError: response.data.isError,
        message: response.data.message,
      })
    );
  }
};

export const asyncUserSetAutomaticPayment = (auto_pay) => async (dispatch) => {
  const conn = new Connection();

  const response = await conn.create("/user/subscription/autopayment/", {
    auto_pay,
  });

  if (conn.getStatusCode() === 200) {
    dispatch(getPlanAutomaticCharges(response.data.charge_automatically));
  } else {
    dispatch(changesError(response));
  }
};

export const asyncUserPermissions = () => async (dispatch) => {
  const conn = new Connection();

  const response = await conn.create("/user/permissions/check/");

  if (conn.getStatusCode() === 200) {
    dispatch(getUserAccountInfo(response.data.userInfo));
  } else {
    dispatch(changesError(response));
  }
};

export default settingsSlice.reducer;
