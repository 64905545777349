import axios from "axios";
import download from "downloadjs";
import { authFetch, logout } from "./AuthProvider";
import { clearStorageInterval, logoutAndRedirect } from "./Index";

// import download from "downloadjs";

export default class Connection {
  /**
   * @param {string} accessToken
   *
   */

  constructor(accessToken, cancelLog) {
    this.cancelLog = cancelLog || false;
    this.accessToken = `Bearer ${accessToken}` || null;
    this.status = { message: "", code: "" };
    const connectionObject = {
      ip: process.env.REACT_APP_BACKEND_HOST,
      port: process.env.REACT_APP_BACKEND_PORT,
      api: process.env.REACT_APP_BACKEND_API,
    };

    this.connectionObject = { ...connectionObject };
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    this.source = source;
  }
  /**
   * @param {string} baseUrl
   * @param {*} data
   *
   */
  create(baseUrl, data, isAuth = true, cancelLog = false) {
    this.cancelLog = cancelLog;

    return this[isAuth ? "request" : "tokenLessRequest"]("post", baseUrl, data);
  }
  /**
   * @param {string} baseUrl
   * @param {(string | number)} id
   *
   */
  delete(baseUrl, id) {
    return this.request("delete", `${baseUrl}/${id}`);
  }
  /**
   * @param {string} baseUrl
   * @param {*} data
   *
   */
  update(baseUrl, data) {
    return this.request("patch", baseUrl, data);
  }
  /**
   * @param {string} baseUrl
   */
  get(baseUrl, data, cancelLog = false) {
    this.cancelLog = cancelLog;
    return this.request("GET", baseUrl, data);
  }

  setData(data = {}) {
    this.data = data;
  }
  getLastRequestData() {
    return this.data ? this.data : null;
  }

  setHeaders(status, headers) {
    this.header = { status, headers };
  }

  setReportData(data) {
    this.header = { data };
  }

  catchError(error) {
    throw error;
  }

  setStatus(code, message) {
    this.status = { code, message };
  }
  setStatusCode(code) {
    this.status.code = code;
  }
  setStatusMessage(message) {
    this.status.message = message;
  }

  getStatusCode() {
    return this.status.code;
  }

  getStatusMessage() {
    return this.status.message;
  }

  getStatusObject() {
    return { ...this.status };
  }
  getResponse() {
    return { status: { ...this.status }, data: { ...this.data } };
  }
  getData() {
    return { ...this.data };
  }
  cancel() {
    this.source && this.source.cancel("Operation canceled by the user.");
  }

  async request(method, url, data) {
    try {
      const response = await authFetch(
        `${this.connectionObject.ip}${this.connectionObject.port}${this.connectionObject.api}` +
          url,
        {
          method,
          mode: "cors",
          body: JSON.stringify(data),
        }
      );
      try {
        const parseResponse = await response.json();
        if (
          parseResponse.status_code === 401 &&
          parseResponse.error === "InvalidTokenHeader"
        ) {
          logoutAndRedirect("Session expired, Please login again.", "/login");
        } else if ([403, 409, 422].includes(response.status)) {
          throw parseResponse;
        }
        this.setData(parseResponse);
      } catch (error) {
        const newError = new Error(error.message);
        newError.response = {
          ...response,
          data: {
            error: error?.message,
            message: `${response?.status}: Error Parsing data`,
            status_code: response?.status,
          },
        };

        throw newError;
      }
      this.setStatus(response.status, response.statusText);
    } catch (error) {
      throw error;
    }

    return { data: this.getLastRequestData(), status: this.getStatusMessage() };
  }
  async createFormData(url, data, method = "post") {
    try {
      const response = await authFetch(
        `${this.connectionObject.ip}${this.connectionObject.port}${this.connectionObject.api}` +
          url,
        {
          method,
          mode: "cors",
          body: new FormData(data),
        }
      );
      try {
        const parseResponse = await response.json();
        if (
          parseResponse.status_code === 401 &&
          parseResponse.error === "InvalidTokenHeader"
        ) {
          clearStorageInterval("not");
          logout();
          window.location.href = "/login";
        }
        this.setData(parseResponse);
      } catch (error) {
        console.log(error);
        const newError = new Error(response.statusText);
        newError.response = {
          ...response,
          data: {
            error: response.statusText,
            message: `${response.status}: Error Parsing data`,
            status_code: response.status,
          },
        };

        throw newError;
      }
      this.setStatus(response.status, response.statusText);
    } catch (error) {
      throw error;
    }

    return { data: this.getLastRequestData(), status: this.getStatusMessage() };
  }

  async tokenLessRequest(method, url, data) {
    return await axios({
      baseURL: `${this.connectionObject.ip}${this.connectionObject.port}${this.connectionObject.api}`,
      method,
      url,
      data,
    })
      .then((response) => {
        this.setData(response.data);
        this.setStatus(response.status, response.headers);

        return response;
      })
      .catch((error) => this.catchError(error));
  }

  async downloadFiles(url, data = {}) {
    await axios({
      baseURL: `${this.connectionObject.ip}${this.connectionObject.port}${this.connectionObject.api}`,
      url,
      data,
      method: "POST",
      responseType: "blob",
      headers: {
        Authorization: this.accessToken,
      },
    })
      .then((response) => {
        const content = response.headers["filename"];
        this.setData(response.data);
        this.setStatus(response.status, response.headers);
        download(response.data, content);
      })
      .catch((error) => this.catchError(error));
  }

  async uploadFiles(url, data = {}, files) {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      console.log(`${key}: ${value}`);
      formData.append(key, value);
    }

    await authFetch(
      `${this.connectionObject.ip}${this.connectionObject.port}${this.connectionObject.api}` +
        url,
      {
        method: "POST",
        mode: "cors",
        body: formData,
      }
    )
      .then((response) => {
        console.log(response);
        this.setStatusCode(response.status);
        return response.json();
      })
      .then((response) => {
        console.log("SUCCESS RESPONSE: ", response);
        this.getStatusMessage(response.message);
        this.setData(response["data"]);
        return response;
      })
      .catch(this.catchError);
  }

  async updateTranscript(url, data, files) {
    await authFetch(
      `${this.connectionObject.ip}${this.connectionObject.port}${this.connectionObject.api}` +
        url,
      {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        this.setStatus(response.status, response.headers);
        return response.json();
      })
      .then((response) => {
        this.setData(response);
      })
      .catch(this.catchError);

    return { data: this.getLastRequestData(), status: this.getStatusMessage() };
  }

  async getStatus(url, data, files) {
    const formData = new FormData();
    formData.append("url", data.url);
    await axios({
      baseURL: `${this.connectionObject.ip}${this.connectionObject.port}${this.connectionObject.api}`,
      method: "POST",
      url,
      data: formData,
      timeout: 600000,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: this.accessToken,
      },
    }).then((response) => {
      console.log(response);
      console.log("SUCCESS RESPONSE: ", response.data);
      console.log("SUCCESS CODE: ", response.status);
      this.setData(response.data);
      this.setStatus(response.status, response.headers);
      return response;
    });
  }

  async getVideos(url, data, files) {
    console.log("calling get videos");
    const formData = new FormData();
    formData.append("user", data.user);
    await axios({
      baseURL: `${this.connectionObject.ip}${this.connectionObject.port}${this.connectionObject.api}`,
      method: "POST",
      url,
      data: formData,
      headers: {
        Authorization: this.accessToken,
      },
    }).then((response) => {
      console.log(response);
      console.log("SUCCESS RESPONSE: ", response.data);
      console.log("SUCCESS CODE: ", response.status);
      console.log("RESPONSE HEADERS:", response.headers);
      this.setData(response["data"]);
      this.setHeaders(response.status, response.headers);
      this.setReportData(response.data);
      //   download(response["data"], response.headers["filename"]);
      this.setStatus(response.status, response.headers);

      return response;
    });
  }

  async formDataRequest(url, data) {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    return await axios({
      baseURL: `${this.connectionObject.ip}${this.connectionObject.port}${this.connectionObject.api}`,
      method: "POST",
      "Access-Control-Allow-Origin": "*",
      url,
      data: formData,
      headers: {
        Authorization: this.accessToken,
      },
    })
      .then((response) => {
        console.log(response);
        console.log("SUCCESS RESPONSE: ", response.data);
        console.log("SUCCESS CODE: ", response.status);
        console.log("RESPONSE HEADERS:", response.headers);
        this.setData(response["data"]);
        this.setHeaders(response.status, response.headers);
        this.setReportData(response.data);
        //   download(response["data"], response.headers["filename"]);
        this.setStatus(response.status, response.headers);

        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response.data;
      });
  }
}
