import React from "react";
import Routes from "./Routes";
import { useAuth } from "./utils/AuthProvider";
import "./App.scss";
import NavigationContainer from "./features/navigation/Navigation";
import Sidebar from "./features/sidebar/Sidebar";
import { tokenInfo } from "./utils/Index";

function App() {
  const [logged] = useAuth();
  const planInfo = tokenInfo(localStorage.getItem("REACT_TOKEN_AUTH_KEY"));
  //
  const isPlanSelected = planInfo?.plan_details?.plan_is_selected;
  return (
    <div className="app-container">
      <NavigationContainer />
      <div style={{ display: "flex" }}>
        {logged && isPlanSelected && <Sidebar />}
        {/* Contains all routes public or not */}
        <Routes />
      </div>
    </div>
  );
}

export default App;
