import { createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { getLocalStorage } from "../../../utils/Index";
import {
  asyncGetInProgressMedia,
  setProgressToFile,
  setError,
  addInProgressMedia,
  removeInProgressMedia,
  setTechError,
} from "../SplashPageSlice";

export const InputMediaSlice = createSlice({
  name: "inputMedia",
  initialState: {
    thumbnail: "",
    metadata: {},
    uploadResponse: {
      code: null,
    },
  },
  reducers: {
    setInput: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      // login(action.payload);
      // state.loggedIn = true;
      // state.username = action.payload.user;
    },

    setResponse: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.uploadResponse.code = action.payload.code;
      state.uploadResponse.message = action.payload.message;
    },
  },
});

export const { setInput, setResponse } = InputMediaSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const asyncUploadMedia = ({ file, pathname }) => async (dispatch) => {
  const fileUploading = {
    file_id: 0,
    // string === url; object === file object
    filename: typeof file === "string" ? file : file.name,
    progress: 0,
    file_status: "Uploading",
  };
  dispatch(addInProgressMedia([fileUploading]));
  const interval = sessionStorage.getItem("mip");
  if (interval) {
    clearInterval(interval);
    sessionStorage.removeItem("mip");
  }
  const data = { file, pathname };
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    console.log(`${key}: ${value}`);
    formData.append(key, value);
  }
  const access_token = getLocalStorage(
    "REACT_TOKEN_AUTH_KEY",
    "access_token",
    true
  );
  const connectionObject = {
    ip: process.env.REACT_APP_BACKEND_HOST || "",
    port: process.env.REACT_APP_BACKEND_PORT || "",
    api: process.env.REACT_APP_BACKEND_API,
  };
  // data.map((file, index) => formData.append("file", data.input));
  try {
    const response = await Axios({
      baseURL: `${connectionObject.ip}${connectionObject.port}`,
      url: `${connectionObject.api}/media/upload/`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      method: "POST",
      data: formData,
      maxContentLength: 10000000000,
      maxBodyLength: 10000000000,
      onUploadProgress: (progressEvent) => {
        const uploadPercentage = parseInt(
          Math.round((progressEvent.loaded / progressEvent.total) * 20)
        );
        dispatch(setProgressToFile({ file_id: 0, progress: uploadPercentage }));
      },
    });
    // await conn.uploadFilesAxios("/media/upload/", data);
    dispatch(
      setResponse({ code: response.status, message: response.statusText })
    );
    if (response.status === 200) {
      sessionStorage.setItem(
        "mip",
        setInterval(() => {
          dispatch(asyncGetInProgressMedia());
        }, 10000)
      );
      dispatch(asyncGetInProgressMedia());
    }
  } catch (error) {
    const statusCode = error.response?.status;
    const message = error.response?.data?.message;
    const techError = error.response?.data?.error;
    // const { exist, message, variant } = action.payload;
    dispatch(setError(`Error ${statusCode}: ${message}`));
    dispatch(setTechError(`Error ${statusCode}: ${techError}`));
    dispatch(removeInProgressMedia(0));
    // logout();
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export default InputMediaSlice.reducer;
