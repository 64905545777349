import React from "react";
import { Nav, Navbar, NavDropdown, Button, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAuth, logout } from "../../utils/AuthProvider";
import {
  clearStorageInterval,
  getLocalStorage,
  tokenInfo,
} from "../../utils/Index";
import NavBarNotifications from "./notifications/Notifications";

import "./Navigation.scss";
import UserProfileImage from "../../assets/UserProfileImage.svg";
import { resetState } from "../SplashPage/SplashPageSlice";
import { useDispatch, useSelector } from "react-redux";
import aimaLogo from "../../assets/AIMA.png";
import withWatsonWhite from "../../assets/withWatsonWhite.png";
import AimaAlert from "../ReusableComponents/AimaAlert/AimaAlert";

const NavigationComponent = (props) => {
  const dispatch = useDispatch();
  const fullname = getLocalStorage("REACT_TOKEN_AUTH_KEY", "fullname", true);
  const history = useHistory();
  const [logged] = useAuth();
  const account = useSelector((state) => state.media.display.account);
  const notifications = useSelector(
    (state) => state.media.display.notifications
  );
  const { days_left } = useSelector((state) => state.authInfo);
  const UserProfileComponent = () => {
    return (
      <Row className="justify-content-end" noGutters>
        <Col xs="auto">
          <img
            src={UserProfileImage}
            className="navbar-icons navbar-user-icon"
            alt="User Notifications"
          />
        </Col>
        <Col xs="auto">
          {days_left <= 3 ? (
            <span className="navnotifications-count">{"!"}</span>
          ) : (
            ""
          )}
        </Col>
      </Row>
    );
  };

  const planInfo = tokenInfo(localStorage.getItem("REACT_TOKEN_AUTH_KEY"));

  const plan_expired = planInfo?.plan_details?.plan_expired;
  let plan_message = planInfo?.plan_details?.plan;
  const isTrial = planInfo?.plan_details?.plan_is_trial;
  let plan_description = "";
  const daysLeft = planInfo?.plan_details?.plan_days_left;
  if (plan_message === null) {
    plan_message = "No Plan";
  }

  if (plan_expired === null) {
    plan_description = "";
  } else if (plan_expired) {
    plan_description = <span style={{ color: "red" }}>(End)</span>;
  } else if (isTrial) {
    plan_description = (
      <span className={daysLeft < 3 && "text-danger"}>
        {`(${daysLeft} Day Trial)`}
      </span>
    );
  } else {
    plan_description = (
      <span className={daysLeft < 3 && "text-danger"}>
        {`(${daysLeft} Day${daysLeft > 1 || daysLeft < 1 ? "s" : ""})`}
      </span>
    );
  }
  const isPlanSelected = planInfo?.plan_details?.plan_is_selected;
  
  return (
    <>
      <AimaAlert />
      <Navbar expand="lg" className="navbar">
        <Navbar.Brand
          //
          onClick={() => (planInfo === null || isPlanSelected === true) && history.push("/")}
        >
          <h1>
            <img
              className={`ml-1 ${isPlanSelected === true && "cursor-pointer"}`}
              src={aimaLogo}
              alt="Aima Lab logo"
              id="logo"
            />

            <img
              className={`navbar__with-watson ${
                isPlanSelected === true && "cursor-pointer"
              }`}
              src={withWatsonWhite}
              alt="With Watson White"
            />
          </h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" id="nav-toggle" />
        <Navbar.Collapse>
          {logged ? (
            <Nav className="ml-auto">
              {notifications && <NavBarNotifications />}
              <div className="pr-5"></div>
              {account && (
                <NavDropdown
                  id="navuser"
                  className="navuser"
                  title={<UserProfileComponent />}
                  alignRight
                >
                  <NavDropdown.Item
                    className="navuser-item navuser-item-heading"
                    disabled
                  >
                    Hello, {fullname}!
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    disabled
                    className="navuser-item navuser-item-plan"
                  >
                    {plan_message} {plan_description}
                  </NavDropdown.Item>
                  <NavDropdown.Divider className="navbar-divider" />
                  <NavDropdown.Item
                    className="navuser-item"
                    onClick={() => history.push("/settings")}
                  >
                    Settings
                  </NavDropdown.Item>
                  <NavDropdown.Divider className="navbar-divider" />
                  <NavDropdown.Item
                    className="navuser-item"
                    onClick={() => {
                      dispatch(resetState());
                      clearStorageInterval("not");
                      logout();
                      history.push("/login");
                    }}
                  >
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
          ) : (
            <Navbar.Collapse>
              <Nav className="ml-auto">
                <Button
                  variant="outline-dark pl-5 pr-5"
                  onClick={() => {
                    // const data = {
                    //   tab: "login",
                    // };
                    // history.replace("/login", data);
                    window.location.href = "/login";
                  }}
                >
                  Login
                </Button>
              </Nav>
            </Navbar.Collapse>
          )}
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};
export default NavigationComponent;
