import { Alert } from "react-bootstrap";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearError } from "../../SplashPage/SplashPageSlice";

const AimaAlert = ({ style }) => {
  const { exist, message, variant } = useSelector((state) => state.media.error);
  const dispatch = useDispatch();
  useEffect(() => {
    exist && setTimeout(() => dispatch(clearError()), 5 * 1000);
    return () => {};
  }, [dispatch, exist]);
  return (
    <div style={style || { position: "absolute", top: "10px", width: "100%" }}>
      <Alert
        show={exist}
        dismissible
        style={{ width: "50%", margin: "0 auto", textAlign: "center" }}
        variant={variant}
      >
        {message}
      </Alert>
    </div>
  );
};

export default AimaAlert;
