import { createSlice } from "@reduxjs/toolkit";
import { login } from "../../../utils/AuthProvider";
import Connection from "../../../utils/Connection";
import Axios from "axios";

const host = process.env.REACT_APP_BACKEND_HOST;
const port = process.env.REACT_APP_BACKEND_PORT;
const api = process.env.REACT_APP_BACKEND_API;

export const authSlice = createSlice({
  name: "authInfo",
  initialState: {
    username: "",
    loggedIn: false,
    loginMessage: {},
    forgotPasswordMessage: {},
  },

  reducers: {
    signIn: (state, action) => {
      state.loggedIn = true;
      state.username = action.payload.user;
      state.plan = action.payload.plan;
      state.days_left = action.payload.plan_days_left;
    },
    loginMessage: (state, action) => {
      state.loginMessage = action.payload;
    },
    forgotPasswordMsg: (state, action) => {
      state.forgotPasswordMessage = action.payload;
    },
  },
});

export const { signIn, loginMessage, forgotPasswordMsg } = authSlice.actions;

export const asyncSignIn = (username, password) => async (dispatch) => {
  const data = new FormData();
  data.append("username", username);
  data.append("password", password);

  await Axios({
    baseURL: `${host || ""}${port || ""}`,
    method: "POST",
    url: `${api}/user/login/`,
    data,
  })
    .then((response) => {
      login(response.data);
      dispatch(signIn(response.data));
    })
    .catch((error) => {
      if (!error.response) {
        dispatch(
          loginMessage({
            error: "NetworkError",
            message: "Error: Network Error",
            status_code: 500,
          })
        );
      } else {
        dispatch(loginMessage(error.response.data));
      }
    });
};

export const forgotPassword = (email) => async (dispatch) => {
  const userEmail = {
    email: email,
  };

  const conn = new Connection();

  const response = await conn.create("/user/forgot/password/", userEmail);

  if (response.status === "OK") {
    dispatch(forgotPasswordMsg(response.data));
  } else {
    dispatch(forgotPasswordMsg(response.data));
  }
};

export const resetPassword = (passwordInfo) => async (dispatch) => {
  const conn = new Connection();

  const response = await conn.create("/user/reset/password/", {
    hash: passwordInfo.password.hash,
    newpassword: passwordInfo.password.password,
  });

  if (response.status === "OK") {
    dispatch(forgotPasswordMsg(response.data));
    setTimeout(() => dispatch(forgotPasswordMsg({})), 3000);
  } else {
    dispatch(forgotPasswordMsg(response.data));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export default authSlice.reducer;
