import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useState } from "react";
import { createFolder } from "../../SplashPage/SplashPageFunctions";
import { useDispatch, useSelector } from "react-redux";
import { unshiftToMediaList } from "../../SplashPage/SplashPageSlice";
import { checkFolderSpecialChars } from "../../../utils/Index";

const CreateFolderModal = ({ isOpen, handleClose }) => {
  const resetedAlert = {
    show: false,
    message: "",
    variant: "danger",
  };
  const [alert, setAlert] = useState(resetedAlert);
  const [error, setError] = useState(false);
  const mediaList = useSelector((state) => state.media.mediaList);
  const folder_id = useSelector((state) => state.media.folder_id);

  const dispatch = useDispatch();
  const handleCreateFolder = async (e) => {
    e.preventDefault();

    const folderName = e.target.name.value;
    //check if the folder exist
    if (error) return;
    if (checkIfFolderExist(folderName)) {
      setAlert({
        ...alert,
        show: true,
        message: `The folder name "${folderName}" already exists at this level.`,
      });
      delayedClearAlert();
    }
    //if everything is fine
    else {
      const response = await createFolder(folderName, folder_id);

      if (response.status.code === 200) {
        //push the new folder at the begining of the current media list
        const folderInfo = {
          ...response.data.folderInfo,
          file_sec: mediaList.length,
        };
        dispatch(unshiftToMediaList([folderInfo]));
        handleClose();
      } else {
        const { isError, message } = response.data;
        setAlert({ ...alert, show: isError, message });
        delayedClearAlert();
        console.error(response);
      }
    }
  };

  const checkIfFolderExist = (folderName) =>
    mediaList.some(
      (media) => media.filename === folderName && media.category === "folder"
    );
  // const handleCloseAlert = () => {
  //   setAlert({ ...alert, show: false });
  // };
  const delayedClearAlert = (params) => {
    setTimeout(handleResetAlert, 5000);
  };

  const handleResetAlert = () => {
    setAlert({ show: false, message: "", variant: "danger" });
  };
  // const handleOpenAlert = () => {
  //   setAlert({ ...alert, show: true });
  // };
  // const handleChangeMessage = (message) => {
  //   setAlert({ ...alert, message });
  // };
  // const handleChangeVariant = (variant) => {
  //   setAlert({ ...alert, variant });
  // };
  const handleSpecialChars = (e) => {
    // don't allow:  \ / : * ? " < > |
    setError(!checkFolderSpecialChars(e.target.value));
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={handleClose}
        // size="sm"
        // onClick={(e) => e.preventDefault()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form name="createForm" onSubmit={handleCreateFolder}>
          <Modal.Body>
            <h4 className="text-center">Create Folder</h4>

            {alert.show && (
              <Alert dismissible show={alert.show} variant={alert.variant}>
                {alert.message}
              </Alert>
            )}
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Folder Name
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  name="name"
                  required
                  onChange={handleSpecialChars}
                />
                {error && (
                  <h5 className="text-danger font-weight-light">
                    A folder name can't contain any of the following characters:
                    &ensp;
                    {` \\ / : * ? " < > |`}
                  </h5>
                )}
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Row className="justify-content-center" style={{ width: "100%" }}>
              <Col>
                <Button
                  block
                  variant="secondary"
                  className="input-buttons"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  block
                  type="submit"
                  // onClick={handleCreateFolder}
                  className="input-buttons button__green-white"
                >
                  OK
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default CreateFolderModal;
