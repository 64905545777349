import { createSlice } from "@reduxjs/toolkit";
import Connection from "../../utils/Connection";
import { setBreadcrumb } from "../SplashPage/SplashPageSlice";

export const ResourceContentSlice = createSlice({
  name: "share_resource",
  initialState: {
    fileid: null,
    pathname: null,
    enrichments: {},
    changesMessage: null,
  },
  reducers: {
    getResourceContent: (state, action) => {
      state.enrichments = action.payload;
    },
    clearResourceContent: (state) => {
      state.enrichments = {};
    },
    setFileId: (state, action) => {
      state.fileid = action.payload?.file_id;
      state.pathname = action.payload?.pathname;
    },
    getMetaInfo: (state, action) => {
      state.metaInfo = action.payload.metadata;
      state.initMetaInfo = action.payload.metadata;
    },
    resetMetaInfo: (state) => {
      state.metaInfo = {};
      state.initMetaInfo = {};
    },
    changesMessage: (state, action) => {
      state.changesMessage = {
        type: action.payload.isError ? "warnning" : "success",
        message: action.payload.message,
      };
    },
    // setMetaInfo: (state, action) => {
    //   state.metaInfo = action.payload;
    // },
    setHandleMetadataChange: (state, action) => {
      state.metaInfo[action.payload.name] = action.payload.value;
    },
    changesError: (state, action) => {
      state.changesMessage = action.payload;
    },
  },
});

export const {
  getResourceContent,
  setFileId,
  changesError,
  clearResourceContent,
  getMetaInfo,
  changesMessage,
  // setMetaInfo,
  setHandleMetadataChange,
  resetMetaInfo,
} = ResourceContentSlice.actions;

export const asyncGetResourceContent = (mediaInfo) => async (dispatch) => {
  const conn = new Connection();

  const response = await conn.create("/media/share/results/", mediaInfo);

  if (conn.getStatusCode() === 200) {
    dispatch(getResourceContent(response.data));
    dispatch(setBreadcrumb(response.data?.breadcrumb));
    dispatch(setFileId(mediaInfo));
  } else {
    response["status_code"] = conn.getStatusCode();
    dispatch(changesError(response));
  }
};

export const asyncGetFileMetadata = (hash) => async (dispatch) => {
  const conn = new Connection();
  try {
    const response = await conn.create("/media/share/metadata/", { hash });
    if (conn.getStatusCode() === 200) {
      dispatch(getMetaInfo(response.data));
    } else {
      dispatch(getMetaInfo({ metadata: {} }));
      dispatch(changesMessage(response.data));
    }
  } catch (error) {
    console.log(error);
    dispatch(getMetaInfo({ metadata: {} }));
    dispatch(changesMessage(error.toString()));
  }
};

// export const asyncChangeFileMetadata = (metadata) => async (dispatch) => {
//   const conn = new Connection();

//   const response = await conn.create("/media/share/metadata/change/", metadata);

//   if (conn.getStatusCode() === 200) {
//     dispatch(getMetaInfo({ metadata }));
//   }

//   dispatch(changesMessage(response.data));
// };
export default ResourceContentSlice.reducer;
