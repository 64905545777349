import {
  SHOW_ERROR,
  selectedTab,
  setVideoTime,
  selectedWord,
  getFullTimes,
  getNewCategories,
} from "../actions/enrichment-actions";

export default function enrichmentReducer(
  state = {
    selectedTab: "",
    time: "",
    transcriptArray: [],
    currentArrayTime: [],
    currentWord: "",
    highlightedWord: "",
  },
  { type, payload, enriched }
) {
  switch (type) {
    case "AJAX_REQUEST":
      return { ...state, enriched: enriched };
    case "AJAX_SUCCESS":
      return { ...state, data: payload, enriched: enriched };
    case "CLEAR_RESULTS":
      return { ...state, data: [], enriched: false };
    case "AJAX_SUCCESS_FOR_UPLOAD":
      return { ...state, data: payload, enriched: true };
    case "SELECTED_TAB":
      return selectedTab(state, payload);
    case "SET_VIDEO_PLAYBACKTIME":
      return setVideoTime(state, payload);
    case "SET_CURRENT_WORD":
      return selectedWord(state, payload);
    case "GET_FULL_TIMES":
      return getFullTimes(state, payload);
    case "SET_NEW_CATS":
      return getNewCategories(state, payload);
    case SHOW_ERROR:
      return payload.enrichments;
    default:
      return state;
  }
}
