import Connection from "../../utils/Connection";

export const SHOW_ERROR = "enrichments:showError";

export const ajaxEnrichRequest = () => {
  return {
    type: "AJAX_REQUEST",
    enriched: false,
  };
};

export const ajaxFetchSuccess = (payload) => {
  return {
    type: "AJAX_SUCCESS",
    payload,
    enriched: true,
  };
};

export const ajaxFetchSuccessForUpload = (payload) => {
  return {
    type: "AJAX_SUCCESS_FOR_UPLOAD",
    payload,
    enriched: true,
  };
};

export const showError = () => {
  return {
    type: "SHOW_ERROR",
    payload: {
      enrichments: "ERROR!",
    },
  };
};

export const removeData = () => {
  return {
    type: "CLEAR_RESULTS",
  };
};

export const selectedTab = (state = {}, payload) => {
  const { tab } = payload;
  return {
    ...state,
    selectedTab: tab,
    enriched: true,
  };
};

// const getMatchingArray = (state, fullArray) => {
//   let matchingArray = [];
//   for (let speaker of state.data.data.speakers) {
//     for (let array of fullArray) {
//       if (speaker.from === array[1] && speaker.to === array[2]) {
//         array.push(speaker.speaker);
//         fullArray.push(array);
//       }
//     }
//   }
//   return {
//     ...state,
//     matchArray: matchingArray,
//   };
// };

export const getFullTimes = (state = {}, payload) => {
  let fullArray = [];
  for (let times of state.data?.data?.alternatives) {
    for (let innerArrayTime of times) {
      for (let wordAndTime of innerArrayTime.timestamps) {
        fullArray.push(wordAndTime);
      }
    }
  }

  // getMatchingArray(state, fullArray);

  return {
    ...state,
    transcriptArray: fullArray,
  };
};

export const getNewCategories = (state = {}, payload) => {
  return {
    ...state,
    newCats: payload,
  };
};

export const setVideoTime = (state = {}, payload) => {
  return {
    ...state,
    time: payload.seconds,
  };
};

export const selectedWord = (state = {}, payload) => {
  return {
    ...state,
    currentWord: payload,
  };
};

export const getEnrichments = async (value, user, id, dispatch) => {
  // return async (dispatch) => {
  dispatch(ajaxEnrichRequest());
  const conn = new Connection();
  await conn.uploadVideo("/results", {
    input: value,
    // isUser: user,
  });

  //   var request = $.ajax({
  //     type: "POST",
  //     url: host + port + "surl",
  //     data: { input: value, isUser: user },
  //   });
  // var success = ajaxFetchSuccessForUpload(conn.getData());

  console.log("success: ---> ", conn.getStatusCode());

  if (conn.getStatusCode().code === 200) {
    console.log(conn.getData());
    dispatch(ajaxFetchSuccess(conn.getData()));
  }
};

export const sendVideo = (value, user) => {
  console.log(value);
  return async (dispatch) => {
    dispatch(ajaxEnrichRequest());
    const conn = new Connection();
    await conn.create("/media/upload/", {
      input: value,
      // isUser: user,
    });

    //   var request = $.ajax({
    //     type: "POST",
    //     url: host + port + "surl",
    //     data: { input: value, isUser: user },
    //   });
    const success = dispatch(ajaxFetchSuccess(conn.getData()));

    console.log("success: ---> ", success);
  };
};
