import { createSlice } from "@reduxjs/toolkit";
import Connection from "../../utils/Connection";
import { setBreadcrumb } from "../SplashPage/SplashPageSlice";

export const MediaResultsSlice = createSlice({
  name: "mediaResultsInfo",
  initialState: {
    fileid: null,
    pathname: null,
    enrichments: {},
    changesMessage: null,
  },
  reducers: {
    getMediaResults: (state, action) => {
      state.enrichments = action.payload;
    },
    clearMediaResults: (state) => {
      state.enrichments = {};
    },
    setFileId: (state, action) => {
      state.fileid = action.payload?.file_id;
      state.pathname = action.payload?.pathname;
    },
    changesError: (state, action) => {
      state.changesMessage = action.payload;
    },
  },
});

export const {
  getMediaResults,
  setFileId,
  changesError,
  clearMediaResults,
} = MediaResultsSlice.actions;

export const asyncGetMediaResults = (mediaInfo) => async (dispatch) => {
  const conn = new Connection();

  const response = await conn.create("/media/results/", mediaInfo);

  if (conn.getStatusCode() === 200) {
    dispatch(getMediaResults(response.data));
    dispatch(setBreadcrumb(response.data?.breadcrumb));
    dispatch(setFileId(mediaInfo));
  } else {
    response["status_code"] = conn.getStatusCode();
    dispatch(changesError(response));
  }
};

export default MediaResultsSlice.reducer;
